import $ from 'jquery';
import {Modal} from "bootstrap";
import fileInput from "@/view/pages/common/fileInput.vue";
import comment from "@/view/pages/common/comment.vue";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
export default {
    components: {
        fileInput,
        comment
    },
    computed: {
        user: {
            get () {return this.$store.getters.currentUser;}
        },
        oaEmpList: {
            get() {
                return this.$store.state.systemData.oaEmpList;
            }
        },
        reportList: {
            get() {
                return this.$store.state.reportStore.reportList;
            }
        },
        yearList: {
            get() {
                return this.$store.state.dateTimeStore.yearList;
            }
        },
        handEmpList: {
            get() {
                return this.$store.state.reportStore.handEmpList;
            }
        }
    },
    watch: {
        user: function () {
            this.initData();
        },
        oaEmpList: function () {
            if (this.oaEmpList && this.oaEmpList.length > 0) {
                if ($('#partIn').is(':visible')) {
                    this.initSelect();
                } else {
                    if (this.temp > 50) {
                        this.temp = 0;
                    }
                    this.temp++;
                    // 递归 等待dom渲染完毕
                    var _this = this;
                    setTimeout(function () {
                        _this.initSelect();
                    }, 500);
                }
            }
        },
        // getOrganizationList: function () {
        //     if (this.organizationList && this.organizationList.length > 0) {
        //         if ($('#architectureIdTwo').is(':visible')) {
        //             this.handleSelectValueTwo();
        //         } else {
        //             if (this.temp > 50) {
        //                 this.temp = 0;
        //             }
        //             this.temp++;
        //             // 递归 等待dom渲染完毕
        //             var _this = this;
        //             setTimeout(function () {
        //                 _this.handleSelectValueTwo();
        //             }, 500);
        //         }
        //     }
        // },

        handEmpList: function () {
            if ($('#handOver').is(':visible')) {
                this.initSelectThree('handOver', this.handOverId, this.handEmpList);
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                var _this = this;
                setTimeout(function () {
                    _this.initSelectThree('handOver', _this.handOverId, _this.handEmpList);
                }, 500);
            }
        },
        reportList: function () {
            $('#loadingModal').modal('hide');
        }
    },
    data() {
        return {
            sharesId: [],
            editStatus: true,
            report: {},
            year: '',
            week: '',
            month: '',
            weekList: [],
            monthList: [],
            search: {architecture:"",architectureThree:""},
            fileList: [],
            recordId: null,
            handOverId: '',
            kybReport: {},
            day: '',
            reportFlag: "1",
            organizationList:[],
            exportUrl: this.url + '/kybReport/export',
            generateTaskContent:"",
        }
    },
    mounted() {
        this.initData();
        this.getStaffList();
        this.delReportT = new Modal(this.$refs.delReport);
        this.editReportT = new Modal(this.$refs.editReport);
    },
    methods: {
        setFileList: function (data) {
            this.fileList = data;
        },
        initSelect: function () {
            let option = '';
            option += "<option value=''>" + '请选择' + '</option>';
            this.oaEmpList.forEach(el => {
                if(el.workStatus == 1)
                {
                    option += "<option value='" + el.recordId + "'>" + el.name + '</option>';
                }
            })
            $('#shareEmp').empty();
            $('#shareEmp').append(option);
            $('#shareEmp').selectpicker('val', this.sharesId);
            $('#shareEmp').selectpicker('render');
            $('#shareEmp').selectpicker('refresh');
            $('#shareEmp').selectpicker();
        },
        initData: function () {
            if (!this.recordId) {
                this.recordId = localStorage.getItem('recordId');
                window.localStorage.removeItem('recordId');
            }
            this.getDate()
            if (this.user && this.user.employeeId) {
                this.$store.dispatch('reportStore/getReportHandOver', this.user);
                this.search = {};
                this.search.status = 1;
                if (this.handOverId) {
                    this.search.employeeId = this.handOverId;
                } else {
                    this.search.employeeId = this.user.employeeId;
                }
                if (this.recordId) {
                    this.search.recordId = this.recordId;
                } else {
                    this.search.startTimeT = $('#startTimeT').val();
                    this.search.endTimeT = $('#endTimeT').val();
                }
                this.$store.dispatch('reportStore/getReportList', this.search);
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }
        },
        searchReport: function () {
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.search.architectureId = "";
            this.search.archEmployeeId = "";
            if (this.handOverId) {
                this.search.employeeId = this.handOverId;
            } else {
                this.search.employeeId = this.user.employeeId;
            }
            this.search.recordId = null;
            if (this.search.architecture)
            {
                for(let one of this.staffList)
                {
                    if (one.recordId == this.search.architecture)
                    {
                        this.search.architectureId = one.groupId;
                        this.search.archEmployeeId = one.recordId;
                        break;
                    }
                }
            }
            this.$store.dispatch('reportStore/getReportList', this.search);
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },
        initSelectThree: function (id, typeId, list) {
            $('#' + id).empty();
            let option = '';
            option += "<option value=''>" + '所有' + '</option>';
            list.forEach(el => {
                option += "<option value='" + el.recordId + "'>" + el.name + '</option>';
            })
            $('#' + id).append(option);
            $('#' + id).selectpicker('val', typeId);
            $('#' + id).selectpicker('render');
            $('#' + id).selectpicker('refresh');
            $('#' + id).selectpicker();
            $('#' + id).selectpicker('val', typeId);
        },
        getReport: function (num) {
            this.reportFlag = num;
            if (num && num > 0) {
                this.search.status = 1;
                switch (num) {
                    // 未读
                    case 1:
                        this.search.status = 1;
                        break;
                    // 已读
                    case 2:
                        this.search.status = 2;
                        break;
                    case 3:
                        this.search.status = '';
                        break;
                }
            }
            this.searchReport();
        },
        delReport: function (item) {
            this.report = item;
            this.delReportT.show();
        },
        deleteReport: function () {
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.$axios.fetchPost('kybReport/deleteReport', this.report).then((data) => {
                if (data && data.data) {
                    if (data.data === 'success') {
                        alert('删除成功');
                    }
                   this.delReportT.hide();
                    this.searchReport();
                }
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }).catch(err => {
                    console.log(err);
                }
            )
        },
        editReport: function (item) {
            this.generateTaskContent = "";
            this.editStatus = true;
            item.loginEmpId = this.user.employeeId;
            this.getReportDeail(item);
        },
        viewReport: function (item) {
            this.editStatus = false;
            item.loginEmpId = this.user.employeeId;
            this.getReportDeail(item);
        },
        getReportDeail: function (item) {
            this.year = {};
            this.week = {};
            this.month = {};
            this.report = {};
            this.sharesId = [];
            this.fileList = [];
            $('#file').val('');
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            if (item && item.recordId) {
                this.$axios.fetchPost('kybReport/getReport', item).then((data) => {
                    if (data && data.data) {
                        this.report = data.data;
                        this.search.architectureThree = data.data.architectureId;
                        this.generateTaskContent = data.data.generateTaskContent;
                        this.sharesId = [];
                        if (data.data.sharesList && data.data.sharesList.length > 0) {
                            for (let i = 0; i < data.data.sharesList.length; i++) {
                                this.sharesId.push(data.data.sharesList[i].recordId);
                            }
                        }
                        if (data.data.attachList) {
                            this.fileList = data.data.attachList;
                        }
                        this.editReportT.show();
                    }
                    this.searchReport();
                    const depart = {};
                    if (this.user.groupId) {
                        const str = this.user.groupId.split(',');
                        depart.recordId = str[0];
                    }
                    depart.workStatus = 1;
                    this.$store.dispatch('systemData/loadOaEmpList', depart);
                }).catch(err => {
                        console.log(err);
                    }
                )
            } else {
                const kybReport = {};
                kybReport.employeeId = this.user.employeeId;
                this.$axios.fetchPost('kybReport/getSuperiorLeaders',kybReport).then((data) => {
                    if (data && data.data)
                    {
                        this.report = data.data;
                    }
                })
                const depart = {};
                if (this.user.groupId) {
                    const str = this.user.groupId.split(',');
                    depart.recordId = str[0];
                }
                depart.workStatus = 1;
                this.$store.dispatch('systemData/loadOaEmpList', depart);
                this.editReportT.show();
            }
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },
        changeType: function () {
            if (!(this.report && this.report.recordId)) {
                if (this.report.flag === '1') {
                    this.initDate();
                } else if (this.report.flag === '2') {
                    this.$store.dispatch('dateTimeStore/setYearList');
                } else if (this.report.flag === '3') {
                    this.$store.dispatch('dateTimeStore/setYearList');
                }
            }
        },
        initDate: function (startDates) {
            const _this = this;
            if ($('#day').is(':visible')) {
                $('#day').daterangepicker({
                    'singleDatePicker': true,
                    'showDropdowns': true,
                    'timePicker': false,
                    'timePicker24Hour': true,
                    'startDate': startDates, // 设置开始日期
                    'opens': 'center',
                    'drops': 'down',
                    'locale': {
                        'format': 'YYYY-MM-DD',
                        'separator': ' - ',
                        'applyLabel': '确定',
                        'cancelLabel': '取消',
                        'fromLabel': 'From',
                        'toLabel': '到',
                        'customRangeLabel': 'Custom',
                        'weekLabel': 'W',
                        'daysOfWeek': [
                            '日',
                            '一',
                            '二',
                            '三',
                            '四',
                            '五',
                            '六'
                        ],
                        'monthNames': [
                            '一月',
                            '二月',
                            '三月',
                            '四月',
                            '五月',
                            '六月',
                            '七月',
                            '八月',
                            '九月',
                            '十月',
                            '十一月',
                            '十二月'
                        ],
                        'firstDay': 1
                    }
                }, function (start) {
                    _this.report.day = start.format('YYYY-MM-DD');
                    _this.dayTask();
                })
                _this.report.day = $('#day').val();
                _this.dayTask();
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function () { _this.initDate(startDates) }, 500);
            }
        },
        initQueryDate: function (id, startDates) {
            if ($('#' + id + '').is(':visible')) {
                const _this = this;
                $('#' + id + '').daterangepicker({
                    'singleDatePicker': true,
                    'showDropdowns': true,
                    'timePicker': true,
                    'timePicker24Hour': false,
                    'startDate': startDates, // 设置开始日期
                    'opens': 'center',
                    'drops': 'down',
                    'locale': {
                        'format': 'YYYY-MM-DD',
                        'separator': ' - ',
                        'applyLabel': '确定',
                        'cancelLabel': '取消',
                        'fromLabel': 'From',
                        'toLabel': '到',
                        'customRangeLabel': 'Custom',
                        'weekLabel': 'W',
                        'daysOfWeek': [
                            '日',
                            '一',
                            '二',
                            '三',
                            '四',
                            '五',
                            '六'
                        ],
                        'monthNames': [
                            '一月',
                            '二月',
                            '三月',
                            '四月',
                            '五月',
                            '六月',
                            '七月',
                            '八月',
                            '九月',
                            '十月',
                            '十一月',
                            '十二月'
                        ],
                        'firstDay': 1
                    }
                }, function (start) {
                    if (id === 'startTimeT') {
                        _this.search.startTimeT = start.format('YYYY-MM-DD');
                    } else if (id === 'endTimeT') {
                        _this.search.endTimeT = start.format('YYYY-MM-DD');
                    }
                    _this.searchReport();
                })
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function () { _this.initQueryDate(id, startDates) }, 500);
            }
        },
        getDate: function () {
            let startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 2);
            let startYear = startDate.getFullYear();
            let startMonth = startDate.getMonth() + 1;
            if (startMonth.length > 1) {
                startMonth = '0' + startDate.getMonth() + 1;
            } else {
                startMonth = startDate.getMonth() + 1;
            }
            let startDay = startDate.getDate() >= 10 ? startDate.getDate() : '0' + startDate.getDate();
            let startTimeT = startYear + '-' + startMonth + '-' + startDay;

            let endDate = new Date();
            let endYear = endDate.getFullYear();
            let endMonth = endDate.getMonth() + 1;
            endMonth = (endMonth < 10 ? '0' + endMonth : endMonth);
            let endDay = endDate.getDate() >= 10 ? endDate.getDate() : '0' + endDate.getDate();
            let endTimeT = (endYear + '-' + endMonth + '-' + endDay);

            this.initQueryDate('startTimeT', startTimeT);
            this.initQueryDate('endTimeT', endTimeT);
        },
        dayTask: function () {
            this.generateTaskContent = '';
            const query = {};
            query.flag = this.report.flag;
            query.employeeId = this.user.employeeId;
            if (this.report.flag === '1') {
                query.completeTime = this.report.day;
            } else if (this.report.flag === '2') {
                let week = this.week < 10 ? '0' + this.week.toString() : this.week.toString();
                query.completeTime = this.year.toString() + week;
            } else if (this.report.flag === '3') {
                let month = this.month < 10 ? '0' + this.month.toString() : this.month.toString();
                query.completeTime = this.year.toString() + month;
            }
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.$axios.fetchPost('kybReport/taskAllConent', query).then((data) => {
                if (data && data.data) {
                    this.generateTaskContent = data.data;
                }
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }).catch(err => {
                    console.log(err);
                }
            )
        },
        getMonth: function () {
            for (let i in this.yearList) {
                if (this.year === this.yearList[i].id) {
                    this.monthList = this.yearList[i].monthList;
                    break;
                }
            }
        },
        getWeek: function () {
            for (let i in this.yearList) {
                if (this.year === this.yearList[i].id) {
                    this.weekList = this.yearList[i].weekList;
                    break;
                }
            }
        },
        setMonth: function () {
            for (let i = 0; i < this.monthList.length; i++) {
                if (this.month === this.monthList[i].id) {
                    this.report.day = this.monthList[i].queryDate;
                    const query = {};
                    query.day = this.monthList[i].queryDate;
                    query.flag = 3;
                    query.employeeId = this.user.employeeId;
                    query.recordId = '';
                    //弹窗开始
                    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                    this.$axios.fetchPost('kybReport/getReport', query).then((data) => {
                        if (data && data.data) {
                            if (data.data.recordId) {
                                alert('该报告已经发布，请前往列表中修改');
                                $('#editReport').modal('hide');
                            }
                        }
                        //弹窗结束
                        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    }).catch(err => {
                            console.log(err);
                        }
                    )
                    break;
                }
            }
            this.dayTask();
        },
        setWeek: function () {
            for (let i = 0; i < this.weekList.length; i++) {
                if (this.week === this.weekList[i].id) {
                    this.report.day = this.weekList[i].startTime;
                    const query = {};
                    query.day = this.weekList[i].startTime;
                    query.flag = 2;
                    query.employeeId = this.user.employeeId;
                    query.recordId = '';
                    //弹窗开始
                    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                    this.$axios.fetchPost('kybReport/getReport', query).then((data) => {
                        if (data && data.data) {
                            if (data.data.recordId) {
                                alert('该报告已经发布，请前往列表中修改');
                                $('#editReport').modal('hide');
                            }
                        }
                        //弹窗结束
                        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    }).catch(err => {
                            console.log(err);
                        }
                    )
                    break;
                }
            }
            this.dayTask();
        },
        saveReport: function () {
            this.report.employeeId = this.user.employeeId;
            this.report.createdBy = this.user;
            if (!this.report.flag) {
                alert('请选择报告类型');
                return;
            }
            if (!this.report.day) {
                alert('请选择报告时间')
                return;
            }
            if (!this.report.content) {
                alert('请填写工作成效');
                return;
            }
            if (!this.report.contentTwo) {
                alert('请填写总结心得');
                return;
            }
            if (!this.report.contentThree) {
                alert('请填写计划内容');
                return;
            }
            this.report.year = this.year;
            this.report.month = this.month;
            this.report.week = this.week;
            this.report.sharesList = [];
            if (this.sharesId) {
                for (let i = 0; i < this.sharesId.length; i++) {
                    const emp = {};
                    emp.recordId = this.sharesId[i];
                    if (emp.recordId !== '') {
                        this.report.sharesList.push(emp);
                    }
                }
            }
            this.report.architectureId = this.user.groupId;
            this.report.generateTaskContent = this.generateTaskContent;
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            // 通过form表单进行提交
            const param = new FormData()
            param.append('report', JSON.stringify(this.report))
            if (this.fileList && this.fileList.length > 0) {
                for (let i = 0; i < this.fileList.length; i++) {
                    if (!this.fileList[i].downloadUrl) {
                        param.append('files', this.fileList[i]);
                    }
                }
            }
            this.$axios.formPost('kybReport/saveReport', param).then((data) => {
                if (data.data === 'success') {
                    alert('提交成功');
                    this.editReportT.hide();
                    this.searchReport();
                } else if (data.data === 'false') {
                    alert('文件上传失败');
                    this.editReportT.hide();
                    this.searchReport();
                } else {
                    alert(data.data);
                    this.editReportT.hide();
                    this.searchReport();
                }
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }).catch(err => {
                    console.log(err);
                }
            )
        },
        getStaffList() {
            this.$axios.fetchPost("hr/employeeCenter/employeeProfile/getEmployeeListT", {}).then(result => {
                this.staffList = result.data;
                this.handleSelectValueT();
            }).catch(err => {
                console.log(err);
            })
        },
        handleSelectValueT() {
            $('#architectureIdTh').empty();
            let option = '';
            option += "<option value=''>" + '请选择' + '</option>';
            this.staffList.forEach(el => {
                option += "<option value='" + el.recordId + "'>" +el.path+'-'+ el.name + '</option>';
            });
            $('#architectureIdTh').append(option);
            $('#architectureIdTh').selectpicker('val', this.search.architecture);
            $('#architectureIdTh').selectpicker('render');
            $('#architectureIdTh').selectpicker('refresh');
            $('#architectureIdTh').selectpicker();
        },
    }
}